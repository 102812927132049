import { Script } from 'gatsby';
import React from 'react';
import { getAPIRoot } from '../../../helpers/fetch';
import ContributionDetailTemplate from '../../../templates/contributionDetail';
import { trackNotFoundEvent } from '../../../utils/application-insights';
import NotFoundPage from '../../404';

const WinnerDetail = ({ serverData: { contributionData } }) => {
  return contributionData ? (
    <>
      <ContributionDetailTemplate contributionData={contributionData} />
      <Script
        id="contributionData"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              event: 'custom_page_view',
              page_title: document.title,
              ID: new Date().getTime(),
              awardYear: ${contributionData.awardYear},
              goldAward: ${contributionData.goldAward},
              discipline: "${contributionData.discipline}",
              category: "${contributionData.category}",
              designer: "${contributionData.designer?.[0]}",
              client: "${contributionData.clients?.[0]}",
              ...params,
            });
          `,
        }}
      />
    </>
  ) : (
    <NotFoundPage />
  );
};

export async function getServerData(ctx) {
  const id = ctx.params.id;
  const apiRoot = await getAPIRoot();
  const locale = ctx.pageContext.locale || 'en';
  const apiUrl = `${apiRoot}${process.env.GATSBY_API_PROJECT_DETAIL}/${id}?language=${locale}`;
  let res = null;

  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error(
        `Fetching failed. Error: ${res.status} - ${res.statusText} ${res.url}`
      );
    }

    if (res.status === 204) {
      return {
        status: 301,
        headers: {
          Location: `/${locale}/winner-ranking/winner-overview/`,
        },
        props: {},
      };
    }

    const contributionData = await res.json();
    const regexSlug = new RegExp(contributionData.slug);
    if (ctx.url.match(regexSlug)) {
      return {
        status: 200,
        props: { contributionData },
      };
    } else {
      return {
        status: 301,
        headers: {
          Location: `/${locale}${contributionData.slug}`,
        },
        props: {},
      };
    }
  } catch (error) {
    trackNotFoundEvent({
      type: 'Entry Detail',
      id,
      locale,
      apiRoot,
      apiUrl,
      apiStatusCode: res?.status,
    });

    return {
      status: 404,
      headers: {},
      props: {},
    };
  }
}

export default WinnerDetail;
