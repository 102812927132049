import React from 'react';
import { getAPIRoot } from '../../../helpers/fetch';
import ContributionDetailTemplate from '../../../templates/contributionDetail';
import NotFoundPage from '../../404';
import { trackNotFoundEvent } from '../../../utils/application-insights';

const WinnerDetail = ({ serverData: { contributionData } }) => {
  return contributionData ? (
    <ContributionDetailTemplate contributionData={contributionData} />
  ) : (
    <NotFoundPage />
  );
};

export async function getServerData(ctx) {
  const id = ctx.params.id;
  const apiRoot = await getAPIRoot();
  const locale = ctx.pageContext.locale || 'en';
  const apiUrl = `${apiRoot}${process.env.GATSBY_API_PROJECT_DETAIL}/${id}?language=${locale}`;
  let res = null;

  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error(
        `Fetching failed. Error: ${res.status} - ${res.statusText} ${res.url}`
      );
    }

    if (res.status === 204) {
      return {
        status: 301,
        headers: {
          Location: `/${locale}/winner-ranking/winner-overview/`,
        },
        props: {},
      };
    }

    const contributionData = await res.json();
    const regexSlug = new RegExp(contributionData.slug);
    if (ctx.url.match(regexSlug)) {
      return {
        status: 200,
        props: { contributionData },
      };
    } else {
      return {
        status: 301,
        headers: {
          Location: `/${locale}${contributionData.slug}`,
        },
        props: {},
      };
    }
  } catch (error) {
    trackNotFoundEvent({
      type: 'Entry Detail',
      id,
      locale,
      apiRoot,
      apiUrl,
      apiStatusCode: res?.status,
    });

    return {
      status: 404,
      headers: {},
      props: {},
    };
  }
}

export default WinnerDetail;
